import Highway from '@dogstudio/highway'
import {TimelineLite} from 'gsap'

class Fade extends Highway.Transition{
	in({from, to , done}){

		// Reset Scroll

		function setCookie(cname, cvalue, exdays) {
		  var d = new Date();
		  d.setTime(d.getTime() + (exdays*24*60*60*1000));
		  var expires = "expires="+ d.toUTCString();
		  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Secure";
		}

		function getCookie(cname) {
		  var name = cname + "=";
		  var decodedCookie = decodeURIComponent(document.cookie);
		  var ca = decodedCookie.split(';');
		  for(var i = 0; i <ca.length; i++) {
		    var c = ca[i];
		    while (c.charAt(0) == ' ') {
		      c = c.substring(1);
		    }
		    if (c.indexOf(name) == 0) {
		      return c.substring(name.length, c.length);
		    }
		  }
		  return "";
		}

		var target = to.dataset.routerView;
		var request = from.dataset.routerView;
		var scrollheight = $(window).scrollTop();

		if(request == "home")
		{
			setCookie("scroll",scrollheight,"Fri, 27 Nov 1981 00:00:00 UTC");
			setCookie("scroll",scrollheight,"Thu, 27 Nov 2081 00:00:00 UTC");
		}
		if(target == "home")
		{
			window.scrollTo(0, getCookie("scroll"));
			setCookie("shownProrail",'0',"Thu, 27 Nov 2081 00:00:00 UTC");
		}
		else
		{
			window.scrollTo(0, 0);
		}

    	
		from.remove();

		var target = to.dataset.routerView;

		var div_t = $('#topcloser');
		var div_b = $('#bottomcloser');

		var navbar = $('.navbar');
		const tl0 = new TimelineLite();
		tl0.delay(.8).fromTo(navbar, 0.5, {opacity: '0'}, 
					{opacity: '1'}
				)

		const tl = new TimelineLite();
		tl.delay(.3).fromTo(div_t, 0.5, {height: '50%'}, 
					{top: '0%', height: '0%',
					onComplete: function() {
							done();
							window.documentready();
					}}
				)

		const tl2 = new TimelineLite();
		tl2.delay(.3).fromTo(div_b, 0.5, {height: '50%'}, 
					{bottom: '0%', height: '0%',
					onComplete: function() {
							done();
							window.documentready();
					}}
				)

		}
	out({from, done}){

	    
		var navbar = $('.navbar');
		const tl0 = new TimelineLite();
		tl0.fromTo(navbar, 0.5, {opacity: '1'}, 
					{opacity: '0'}
				)

		
		var div_t = $('#topcloser');
		var div_b = $('#bottomcloser');

		const tl = new TimelineLite();
		tl.fromTo(div_t, 0.5, {top: '0%', height: '0%'}, 
					{height: '50%',
					onComplete: function() {

						if($(".sidenav").hasClass('sideNav_active'))
						{
						  $(".sidenav").toggleClass("sideNav_active");
					      $(".button").toggleClass("active");
					  	}
					  	
						done();
					}}
				)
		
		const tl2 = new TimelineLite();
		tl2.fromTo(div_b, 0.5, {bottom: '0px', height: '0%'}, 
					{height: '50%',
					onComplete: function() {
							done();
					}}
				)
		
		}
}

export default Fade;