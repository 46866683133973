var jquery = require("jquery");
window.$ = window.jQuery=jquery;

require("popper.js");
require("bootstrap");
require("jquery.appear");

import Highway from '@dogstudio/highway'
import Fade from './transition'; 
import {TimelineLite} from 'gsap'


const H = new Highway.Core({
  transitions: {
    default: Fade
  }
})

$(document).ready(function(){
    setCookie("shownProrail",'0',"Thu, 27 Nov 2081 00:00:00 UTC");
    documentready();
});

$(window).scroll(function() { 
  checkAppearance();
  $('#toplink').show();
  $('#scrollbtn').show();

  var height = $(window).scrollTop();
 
  if(height >= 100 )
  {   
      $(".logo").animate({ left: '0px' }, "slow");
  }
  
  var toplinkpos = $('#toplink').offset().top;
  var footerpos = $('#footer').position().top;
  var footerheight = $('#footer').height();

  if(height === 0)
  {
      $('#toplink').hide();
  }
  else
  {
    $('#toplink').show(); 
    $('#scrollbtn').hide();
  }


  var scrolled = $(window).scrollTop()
  $('.parallax').each(function(index, element) {
    var initY = 0
    var height = $(this).height()
    var endY  = initY + $(this).height()

    // Check if the element is in the viewport.
    var visible = isInViewport(this)
    if(visible) {
      var diff = scrolled - initY
      var ratio = Math.round((diff / height) * 100)
      $(this).css('background-position','center ' + parseInt(-(ratio * 1.5)) + 'px')
    }
  })

    
});

window.setCookie = function(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;SameSite=Secure";
    }

window.getCookie = function(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }


window.documentready = function ()
{ 
    checklink();
    checkAppearance();  

    $("#toplink").on('click', function(event) {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      return false;
      event.preventDefault();
    });

    $("#opennav").on('click', function(event) {
      $(".sidenav").toggleClass("sideNav_active");
      $(".button").toggleClass("active");
    });

    $("#closenav").on('click', function(event) {
      $(".sidenav").toggleClass("sideNav_active");
      $(".button").toggleClass("active");
    });


    // Add smooth scrolling to all links
    $("a").on('click', function(event) {
      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();
        // Store hash
        var hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: ($(hash).offset().top)-100
        }, 800, function(){
     
          // Add hash (#) to URL when done scrolling (default click behavior)
          // window.location.hash = hash;

        });
      } // End if
    });
}

window.prorail = function(){

  const tl = new TimelineLite();
  var svg1 = document.querySelector('#svg1');
  var svg2 = document.querySelector('#svg2');
  var svg3 = document.querySelector('#svg3');

  tl.delay(0.7)
    .fromTo('#prorail_image-1',0.5,{css: { top: "-=100"}}, {css:{ top: "+=100"}})
    .to('#prorail_image-1 ', 0.5, {css:{opacity: 1}}, '-=0.5')
    .add(createLineTween(svg1))
    .to('#prorail_title-1 .dot ', 0.1, {css:{opacity: 1}})
    .to('#prorail_title-1 span ', 0.5,{css:{opacity: 1}})
    .fromTo('#prorail_image-2',0.5,{css: { top: "-=100"}}, {css:{ top: "+=100"}}, '-=0.5')
    .to('#prorail_image-2 ', 0.5, {css:{opacity: 1}}, '-=0.5')
    .add(createLineTween(svg2))
    .to('#prorail_title-2 .dot ', 0.1, {css:{opacity: 1}})
    .to('#prorail_title-2 span ', 0.5,{css:{opacity: 1}})
    .fromTo('#prorail_image-3',0.5,{css: { top: "-=100"}}, {css:{ top: "+=100"}}, '-=0.5')
    .to('#prorail_image-3 ', 0.5, {css:{opacity: 1}}, '-=0.5')
    .add(createLineTween(svg3))
    .to('#prorail_title-3 .dot ', 0.1, {css:{opacity: 1}})
    .to('#prorail_title-3 span ', 0.5,{css:{opacity: 1}})


  //this function creates a single tween that animates the stroke of an svg
  function createLineTween(svg) {
    
    console.log(svg);
    var pathObject = {length:0, pathLength:svg.getTotalLength()}; 
    var tween = TweenLite.to(pathObject, 0.3, {length:pathObject.pathLength, onUpdate:drawLine, onUpdateParams:[pathObject, svg], immediateRender:true});
    return tween;
  };


   //update stroke   
   function drawLine(obj, svg) {
    svg.style.strokeDasharray = [obj.length, obj.pathLength].join(' ');
   };

}


window.isInViewport = function(node) {
  var rect = node.getBoundingClientRect()
  return (
    (rect.height > 0 || rect.width > 0) &&
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.left <= (window.innerWidth || document.documentElement.clientWidth)
  )
}


window.checklink = function(){
  // Make sure this.hash has a value before overriding default behavior

      var hash = window.location.hash;
      if (hash !== "") {
        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').delay(1000).animate({
          scrollTop: ($(hash).offset().top)-100
        }, 800);
      } // End if
}

window.checkAppearance = function()
{
      
  $('.maincontent').each(function() {
         var thisid = $(this).attr('id');  

        var scrollheight = $(window).scrollTop();
        var viewport = $(window).height();
        var divheight = $('#'+thisid).height();
        var p = $( '#'+thisid ).last();
        var offset = p.offset();

        if((offset.top+(0.75*divheight))-viewport < scrollheight)
        {      
          if(offset.top+(0.25*divheight) < scrollheight)
          {
            $('.'+thisid+' i').removeClass("fas fa-circle");
            $('.'+thisid+' i').addClass("far fa-circle"); 
          }
          else
          {
            $('.'+thisid+' i').removeClass("far fa-circle");
            $('.'+thisid+' i').addClass("fas fa-circle"); 
          }
        } 
        else if((offset.top+(0.5*divheight))-viewport > scrollheight)
        { 
          $('.'+thisid+' i').removeClass("fas fa-circle");
          $('.'+thisid+' i').addClass("far fa-circle"); 
        }     
        else
        {
          $('.'+thisid+' i').removeClass("fas fa-circle");
          $('.'+thisid+' i').addClass("far fa-circle");
        }       
        
 
        $('#'+thisid).appear(function() {
            showDiv('#'+thisid+' .content','500');  

            var shownProrail = getCookie('shownProrail');
            if(thisid == "portfolio_content1" && shownProrail == "0")
            {
              setCookie("shownProrail",'1',"Thu, 27 Nov 2081 00:00:00 UTC");
              prorail();          
            }
        });  

              
  });

}

window.showDiv = function(myclass,mytimer)
{
   $(myclass).delay(mytimer).animate({opacity: 1.0}, 500);
}

window.hideDiv = function(myclass,mytimer)
{
   $(myclass).delay(mytimer).animate({opacity: 0}, 500);
}
